export const DEFAULT_LINE_COLOR = '#6155F6';
export const DEFAULT_DARK_LINE_COLOR = '#FFD700';

const DEFAULT_COLORS: Array<string> = [
    '#F0142F',
    '#57BEFF',
    '#FF8900',
    '#0058FF',
    '#FFBA00',
    '#5D2FB4',
    '#D900EB',
    '#ACA176',
    '#8B5057',
];

export const BuildColorMap = (
    lineColor: string,
    symbols: Array<string>,
    indexSymbol: string,
    colors: Array<string> = DEFAULT_COLORS
): Map<string, string> => {
    const tempColorMap = new Map<string, string>();

    tempColorMap.set(indexSymbol, lineColor);

    symbols.forEach((symbol, index) => {
        if (symbol === indexSymbol) return;
        tempColorMap.set(symbol, colors[index % colors.length]);
    });

    return tempColorMap;
};

export const hexColorOpacity = (hex: string, opacity: number) => {
    const rgb = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16));

    if (!rgb) {
        return hex;
    }
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
};
