'use client';

import {
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    format,
    startOfYear,
    subDays,
    subMonths,
    subYears,
} from 'date-fns';
import { saveAs } from 'file-saver';
import React, { forwardRef, ReactNode, useCallback, useMemo, useState } from 'react';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useCurrentPng } from 'recharts-to-png';

import { Heading } from '@/components/dom/text-elements';
import Button from '@/components/global/button';
import Icon from '@/components/global/icon';
import { Cube } from '@/components/global/logo';
import { ConvertIndexDataToPercent, FormatDateMonthDayYear, GetLegendData } from '@/components/graph/helpers';
import { BuildColorMap, DEFAULT_LINE_COLOR } from '@/helpers/colors';
import { FormatUtcDate, ParseGqlUtcDate } from '@/helpers/dates';
import { FilterIndexDataDateRange } from '@/helpers/index-data';
import { FormatCurrencyAmount, FormatPercentage } from '@/helpers/numbers';
import { GetIndexSymbolPublicPath } from '@/helpers/paths';
import cn from '@/lib/cn';
import { SITE_BASE_URL } from '@/lib/constants';
import RecessionData from '@/public/data/recessions.json';
import { ProcessedIndexItem } from '@/types/index';

// store some dates we will use throughout the graph
const now = new Date();
const firstDayOfYear = startOfYear(now);

export enum TimeSelectorKeys {
    ONE_MONTH = '1m',
    ONE_WEEK = '1w',
    ONE_YEAR = '1y',
    THREE_MONTHS = '3m',
    THREE_YEARS = '3y',
    SIX_MONTHS = '6m',
    ALL = 'all',
    CUSTOM = 'custom',
    INCEPTION_TO_DATE = 'ITD',
    YEAR_TO_DATE = 'YTD',
}

const TIME_RANGE_TO_TEXT = {
    '1m': '1 month',
    '1w': '1 week',
    '1y': '1 year',
    '3m': '3 months',
    '3y': '3 years',
    '6m': '6 months',
    ITD: 'Inception to date',
    YTD: 'Year to date',
    all: 'All',
    custom: 'Custom',
};

export type TimeSelectorType = keyof typeof TIME_RANGE_TO_TEXT;

export type GraphProps = {
    className?: string;
    cursor?: string;
    defaultIndexName?: string;
    defaultTimeSelector?: TimeSelectorType;
    enableGradient?: boolean;
    height?: number;
    highlightPrimaryOnly?: boolean;
    indexData: Array<ProcessedIndexItem>;
    lineColor?: string;
    obfuscateBackfill?: boolean;
    showDownloadButton?: boolean;
    showIndicators?: boolean;
    showLegend?: boolean;
    showLogo?: boolean;
    showPercentChange?: boolean;
    showRawValues?: boolean;
    showRecessions?: boolean;
    showTimeSelectors?: boolean;
    showTooltip?: boolean;
    showXLabels?: boolean;
    showYLabels?: boolean;
    symbolList: Array<string>;
    title?: string;
    timeSelectorRange?: Array<TimeSelectorKeys>;
};

interface TooltipPayload extends Payload<number | Array<number>, string> {
    name?: string | undefined;
    stroke?: string | undefined;
    value?: number | Array<number> | undefined;
}

const calculateTickInterval = (diff: number, tickCountIsh: number, intervalExponent = 1): number => {
    const BASE_POSSIBLE_INTERVALS = [1, 2, 5];

    if (isNaN(diff)) throw new Error(`Invalid diff ${diff}`);

    // find an interval that is the next greater than diff / maxTickCount
    const interval = BASE_POSSIBLE_INTERVALS.map((interval) => interval * 10 ** intervalExponent).find(
        (val) => val > diff / tickCountIsh
    );
    if (interval) return interval;

    return calculateTickInterval(diff, tickCountIsh, intervalExponent + 1);
};

const generateYTicks = (min: number, max: number, tickCountIsh = 5): Array<number> => {
    const ticks = [];
    const interval = calculateTickInterval(Math.ceil(max - min), tickCountIsh);

    const roundedMin = Math.floor(min / interval) * interval;
    const roundedMax = Math.ceil(max / interval) * interval;

    for (let i = roundedMin; i < roundedMax + interval; i += interval) {
        ticks.push(i);
    }
    return ticks;
};

type PercentChangeProps = {
    indexSymbol: string;
    data: Array<ProcessedIndexItem>;
    colorMap: Map<string, string>;
    symbolList: Array<string>;
};

const getFirstWhereAllHaveValues = (
    symbolList: Array<string>,
    data: Array<ProcessedIndexItem>
): ProcessedIndexItem | undefined => {
    const item = data.find((item) => {
        const symbolValues = item.symbolValues;
        let missedValue = false;
        if (Object.keys(symbolValues).length === symbolList.length) {
            // first check to make sure their lengths match if so we loop through and make sure they all have values
            Object.values(symbolValues).forEach((value) => {
                if (value === undefined || value === null) {
                    missedValue = true;
                }
            });
        } else {
            // if the lengths don't match we know we are missing a value
            missedValue = true;
        }

        return missedValue === false;
    });

    return item;
};

const PercentChange = ({ indexSymbol, symbolList, data, colorMap }: PercentChangeProps) => {
    // TODO: adapt to `data` potentially being raw data, not necessarily percent
    if (data.length < 2) {
        return <></>;
    }

    const firstItem = getFirstWhereAllHaveValues(symbolList, data);
    const lastItem = getFirstWhereAllHaveValues(symbolList, data.slice(0).reverse());

    const changes = symbolList.map((symbol) => {
        if (firstItem && lastItem) {
            return {
                change: lastItem.symbolValues[symbol] - firstItem.symbolValues[symbol],
                symbol,
            };
        }
    });

    return (
        <div className="flex text-center justify-evenly">
            {changes.map((item) => {
                if (item) {
                    const color = colorMap.get(item.symbol);
                    return (
                        <div
                            key={`percentChange-${item.symbol}`}
                            className="mb-4 mr-4"
                        >
                            <div>
                                <span
                                    className="text-3xl"
                                    style={{ color }}
                                >
                                    {(item.change > 0 ? '+' : '') + FormatPercentage(item.change)}
                                </span>
                            </div>
                            <div
                                style={{ color }}
                                className={'text-sm ' + (item.symbol === indexSymbol ? 'underline' : '')}
                            >
                                {item.symbol}
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

const HeaderIndicators = ({
    colorMap,
    indexSymbol,
    timeRangeRawData,
}: {
    colorMap: Map<string, string>;
    indexSymbol: string;
    timeRangeRawData: Array<ProcessedIndexItem>;
}) => {
    const earliestValue = timeRangeRawData.reduce((acc, curr) => (curr.asOfDate < acc.asOfDate ? curr : acc))
        .symbolValues[indexSymbol];
    const latestItem = timeRangeRawData.reduce((acc, curr) => (curr.asOfDate > acc.asOfDate ? curr : acc));
    const latestValue = latestItem.symbolValues[indexSymbol];
    const color = colorMap.get(indexSymbol);

    const change = latestValue - earliestValue;
    const percentChange = (change / latestValue) * 100.0;
    return (
        <>
            <div className="flex flex-col">
                <span className="text-xs text-brand-black dark:text-brand-gray-med">
                    {FormatUtcDate(ParseGqlUtcDate(latestItem.asOfDate), 'MMMM d, yyyy')}
                </span>
                <span
                    className="text-2xl"
                    style={{ color }}
                >
                    {FormatCurrencyAmount(latestValue, { prefix: '' })}
                </span>
            </div>
            <div className={'flex items-center px-2 rounded-sm'}>
                <Icon
                    type="performanceArrow"
                    rotate={percentChange >= 0 ? undefined : 180}
                    color={percentChange >= 0 ? 'teal' : 'danger'}
                    className="h-[5px] w-[10px] mr-2"
                    size="custom"
                />
                <span
                    className={cn('text-lg', {
                        'text-brand-danger': percentChange < 0,
                        'text-brand-teal': percentChange >= 0,
                    })}
                >
                    {FormatCurrencyAmount(change, { prefix: '' })} ({FormatPercentage(percentChange)})
                </span>
            </div>
        </>
    );
};

const FooterIndicators = ({
    indexSymbol,
    fiftyTwoWeekData,
}: {
    indexSymbol: string;
    fiftyTwoWeekData: Array<ProcessedIndexItem>;
}) => {
    const indexDateNavs = fiftyTwoWeekData
        .map((item) => {
            return { asOfDate: item.asOfDate, value: item.symbolValues[indexSymbol] };
        })
        .filter(({ value }) => !!value);
    const indexNavs = indexDateNavs.map(({ value }) => value);
    const low = Math.min(...indexNavs);
    const high = Math.max(...indexNavs);

    const labelColorClass = 'text-brand-black dark:text-white';
    const valueColorClass = 'text-brand-black dark:text-brand-gray-med';

    const indicators = [
        ['52-wk low', FormatCurrencyAmount(low, { prefix: '' })],
        ['52-wk high', FormatCurrencyAmount(high, { prefix: '' })],
    ];

    return (
        <div className="flex text-center justify-evenly">
            {indicators.map(([label, value]) => {
                return (
                    <div
                        key={`metric-${label}`}
                        className="mr-6"
                    >
                        <div className={cn('text-sm text-left', labelColorClass)}>{label}</div>
                        <div>
                            <span className={cn('text-2xl text-left', valueColorClass)}>{value}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const Graph = forwardRef(function Graph(
    {
        className = '',
        cursor,
        defaultIndexName,
        defaultTimeSelector = 'all',
        enableGradient = true,
        height = 200,
        highlightPrimaryOnly = false,
        indexData,
        lineColor = DEFAULT_LINE_COLOR,
        obfuscateBackfill = false,
        showDownloadButton = false,
        showIndicators = false,
        showLegend = true,
        showLogo = false,
        showPercentChange = false,
        showRawValues = false,
        showRecessions = false,
        showTimeSelectors = false,
        showTooltip = true,
        showXLabels = true,
        showYLabels = true,
        symbolList,
        title = '',
        timeSelectorRange = [
            TimeSelectorKeys.ONE_WEEK,
            TimeSelectorKeys.ONE_MONTH,
            TimeSelectorKeys.THREE_MONTHS,
            TimeSelectorKeys.SIX_MONTHS,
            TimeSelectorKeys.ONE_YEAR,
            TimeSelectorKeys.YEAR_TO_DATE,
            TimeSelectorKeys.THREE_YEARS,
            TimeSelectorKeys.INCEPTION_TO_DATE,
        ],
    }: GraphProps,
    forwardedRef
) {
    const [disabledSymbols, setDisabledSymbols] = useState<Array<string>>([]);
    const [exportingImage, setExportingImage] = useState(false);

    const zoomEnabled = showTimeSelectors;
    const [zoomStartEdgeDate, setZoomStartEdgeDate] = useState<string>();
    const [zoomEndEdgeDate, setZoomEndEdgeDate] = useState<string>();

    const [customRange, setCustomRange] = useState<[Date, Date]>();

    const indexSymbol = (defaultIndexName ?? '') || symbolList[0];

    const sortedRawData: Array<ProcessedIndexItem> = useMemo(
        () => indexData.slice().sort((p1, p2) => (p1.asOfDate > p2.asOfDate ? 1 : -1)),
        [indexData]
    );

    const [getPng, { ref }] = useCurrentPng();

    const handleGraphDownload = useCallback(async () => {
        const png = getPng();

        // Download with FileSaver
        png.then((data) => {
            if (data) {
                saveAs(data, `${indexSymbol.toLowerCase()}-export-${format(now, "yyyy-MM-dd--HH':'mm':'ss")}`);
            }
            return data;
        }).then(() => {
            setExportingImage(false);
        });
    }, [getPng, indexSymbol]);

    const lastDateOfData = ParseGqlUtcDate(sortedRawData[sortedRawData.length - 1].asOfDate);
    const firstLiveItem = sortedRawData.find(({ isBackcalculated }) => !isBackcalculated) || null;
    const firstLiveDate = firstLiveItem && ParseGqlUtcDate(firstLiveItem.asOfDate);

    // TODO: migrate to keyed objects instead of tuple-as-array so we can type it better
    const TIME_RANGES: { [key: string]: [Date | null, Date | null] } = useMemo(() => {
        return {
            '1m': [subMonths(lastDateOfData, 1), null],
            '1w': [subDays(lastDateOfData, 7), null],
            '1y': [subYears(lastDateOfData, 1), null],
            '3m': [subMonths(lastDateOfData, 3), null],
            '3y': [subYears(lastDateOfData, 3), null],
            '6m': [subMonths(lastDateOfData, 6), null],
            ITD: [firstLiveDate, null],
            YTD: [firstDayOfYear, null],
            all: [null, null],
            ...(customRange ? { custom: customRange } : {}),
        };
    }, [lastDateOfData, firstLiveDate, customRange]);

    const [selectedTimeRange, setSelectedTimeRange] = useState<TimeSelectorType>(defaultTimeSelector);

    const timeRangeRawData = useMemo(() => {
        const [min, max] = TIME_RANGES[selectedTimeRange];
        return FilterIndexDataDateRange(sortedRawData, min, max);
    }, [sortedRawData, selectedTimeRange, TIME_RANGES]);

    const graphData = useMemo(
        () => (showRawValues ? timeRangeRawData : ConvertIndexDataToPercent(timeRangeRawData)),
        [timeRangeRawData, showRawValues]
    );

    const colorMap = useMemo(() => {
        const colorMap = BuildColorMap(lineColor, symbolList, indexSymbol);
        disabledSymbols.forEach((symbol) => colorMap.set(symbol, 'rgb(87, 87, 87)'));
        return colorMap;
    }, [lineColor, symbolList, indexSymbol, disabledSymbols]);

    const tooltipValueFormatter = useCallback(
        (value: number) => {
            // TODO: handle non-USD?
            return showRawValues ? FormatCurrencyAmount(value, { prefix: '' }) : FormatPercentage(value, { places: 2 });
        },
        [showRawValues]
    );

    const tickValueFormatter = useCallback(
        (value: number) => {
            // TODO: handle non-USD?
            return showRawValues ? FormatCurrencyAmount(value) : FormatPercentage(value, { places: 0 });
        },
        [showRawValues]
    );

    // store some dates we will use throughout the graph
    const firstDateItem = sortedRawData[0];
    const firstDateOfData = ParseGqlUtcDate(sortedRawData[0].asOfDate);
    const firstDateRawValue = firstDateItem.symbolValues[indexSymbol];

    const daysInData = differenceInDays(lastDateOfData, firstDateOfData);
    const monthsInData = differenceInMonths(lastDateOfData, firstDateOfData);
    const yearsInData = useMemo(
        () => differenceInYears(lastDateOfData, firstDateOfData),
        [firstDateOfData, lastDateOfData]
    );

    const timeRangeFirstLiveDate = graphData.find(({ isBackcalculated }) => !isBackcalculated)?.asOfDate;
    const hasBackcalculatedData =
        !obfuscateBackfill && (!timeRangeFirstLiveDate || timeRangeFirstLiveDate > graphData[0].asOfDate);
    const showBackfillInfo = obfuscateBackfill && firstLiveDate && firstLiveDate > firstDateOfData;

    const customToolTip = useCallback(
        ({ active, payload, label }: TooltipProps<number | Array<number>, string>): JSX.Element => {
            if (active && payload && payload.length) {
                // use a map to handle duplicates
                const linesMap: { [key: string]: ReactNode } = {};
                payload.slice(0).forEach((element: TooltipPayload) => {
                    if (element.name === 'none') return;
                    if (element.value === undefined || element.value === null) return;
                    const elementName = element.name as string;
                    if (elementName in linesMap) return;
                    // the value is [min, max] for the area chart, and just a number for the line chart
                    const value = Array.isArray(element.value) ? element.value[1] : element.value;

                    const tooltipText = elementName || indexSymbol;

                    if (highlightPrimaryOnly && elementName !== indexSymbol) return;

                    linesMap[elementName] = (
                        <li key={elementName}>
                            <span className="flex flex-column justify-between items-end">
                                <p>
                                    <span
                                        className="mr-1 text-xl"
                                        style={{
                                            color: colorMap.get(elementName as string),
                                        }}
                                    >
                                        &#8226;
                                    </span>
                                    {tooltipText}
                                </p>
                                <p className="ml-4">{tooltipValueFormatter(value)}</p>
                            </span>
                        </li>
                    );
                });

                const lines = Object.values(linesMap);
                return (
                    <div className="bg-white p-4 border border-brand-gray text-brand-black">
                        <div className="flex flex-column">
                            <h3 className="mr-4 font-brand-bold">{FormatDateMonthDayYear(label)}</h3>
                        </div>
                        <ul>{lines}</ul>
                    </div>
                );
            }

            return <></>;
        },
        [colorMap, indexSymbol, highlightPrimaryOnly, tooltipValueFormatter]
    );

    if (showPercentChange && showRawValues) {
        throw new Error('Cannot show percent change for raw values');
    }

    if (showIndicators && !showRawValues) {
        throw new Error('Cannot show high/low for non-raw values');
    }

    const TimeSelectorButton = (props: { label?: string; className?: string; timeRange: TimeSelectorType }) => {
        const { label, timeRange, className } = props;
        const isSelected = selectedTimeRange === timeRange;
        const timeSelectedStyle = 'font-brand-bold border border-black py-1 px-3.5';
        const timeNotSelectedStyle = 'py-1.5 px-4 border-0';

        return (
            <Button
                type="action"
                onClick={() => {
                    setCustomRange(undefined);
                    setSelectedTimeRange(timeRange);
                }}
                className={cn(className, isSelected ? timeSelectedStyle : timeNotSelectedStyle)}
                roundedCorners="md"
                color="transparent"
                title={TIME_RANGE_TO_TEXT[timeRange]}
            >
                {label || timeRange}
            </Button>
        );
    };

    const daysInDataCheck: {
        [key in TimeSelectorKeys]?: boolean;
    } = useMemo(
        () => ({
            [TimeSelectorKeys.ONE_WEEK]: daysInData >= 7,
            [TimeSelectorKeys.ONE_MONTH]: monthsInData >= 1,
            [TimeSelectorKeys.THREE_MONTHS]: monthsInData >= 3,
            [TimeSelectorKeys.SIX_MONTHS]: monthsInData >= 6,
            [TimeSelectorKeys.ONE_YEAR]: yearsInData >= 1,
            [TimeSelectorKeys.THREE_YEARS]: yearsInData >= 3,
            [TimeSelectorKeys.YEAR_TO_DATE]: firstDateOfData < firstDayOfYear && lastDateOfData >= firstDayOfYear,
            [TimeSelectorKeys.INCEPTION_TO_DATE]: !!firstLiveDate && firstLiveDate < subDays(lastDateOfData, 7),
        }),
        [daysInData, firstDateOfData, firstLiveDate, lastDateOfData, monthsInData, yearsInData]
    );

    // TODO: casing on label
    const TimeSelectors = (
        <div className="flex justify-end grow flex-wrap gap-x-1 gap-y-1">
            {timeSelectorRange
                .filter((timeSelectorKey) => daysInDataCheck[timeSelectorKey])
                .map((timeRange) => {
                    return (
                        <TimeSelectorButton
                            key={timeRange}
                            timeRange={timeRange}
                        />
                    );
                })}
            <TimeSelectorButton
                timeRange="all"
                label="All"
            />
        </div>
    );

    const Recessions = (data: Array<ProcessedIndexItem>) => {
        type Recession = {
            trueStart: Date;
            trueEnd: Date;
            graphStart: string | null;
            graphEnd: string | null;
        };

        const recessions: Array<Recession> = RecessionData.recessions.map((recession) => {
            return {
                graphEnd: null,
                graphStart: null,
                trueEnd: new Date(recession.end),
                trueStart: new Date(recession.start),
            };
        });

        data.forEach((item) => {
            recessions.forEach((recession) => {
                const check = new Date(item.asOfDate);
                if (check >= recession.trueStart && check <= recession.trueEnd) {
                    if (recession.graphStart === null) {
                        recession.graphStart = item.asOfDate;
                    }
                    recession.graphEnd = item.asOfDate;
                }
            });
        });

        const recessionBars: Array<JSX.Element> = [];
        recessions.forEach((recession, index) => {
            if (recession.graphStart !== null && recession.graphEnd !== null) {
                recessionBars.push(
                    <ReferenceArea
                        key={`recession-${index}`}
                        x1={recession.graphStart}
                        x2={recession.graphEnd}
                        fill="gray"
                        fillOpacity={0.1}
                    />
                );
            }
        });

        return recessionBars;
    };

    const border = '#DEDEDE';
    const zeroLine = '#22223C';

    const minValue = Math.min(
        ...graphData.map((i) => {
            const values = Object.values(i.symbolValues);
            return Math.min(...values);
        })
    );

    const maxValue = Math.max(
        ...graphData.map((i) => {
            const values = Object.values(i.symbolValues);
            return Math.max(...values);
        })
    );

    const chart = useMemo(() => {
        const getArea = (symbol: string, min: number) => {
            if (disabledSymbols.includes(symbol)) return null;
            const color = colorMap.get(symbol);
            const gradientName = `graphGradient${color}`;
            const dataKey = (item: ProcessedIndexItem) => {
                return [min, item.symbolValues[symbol]];
            };

            return (
                <>
                    <defs>
                        <linearGradient
                            id={gradientName}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="30%"
                                stopColor={color}
                                stopOpacity={0.4}
                            />
                            <stop
                                offset="50%"
                                stopColor={color}
                                stopOpacity={0.2}
                            />
                            <stop
                                offset="65%"
                                stopColor={color}
                                stopOpacity={0.05}
                            />
                            <stop
                                offset="80%"
                                stopColor={color}
                                stopOpacity={0}
                            />
                        </linearGradient>
                    </defs>
                    <Area
                        type="monotone"
                        fill={`url(#${gradientName})`}
                        stopColor={color}
                        fillOpacity={1}
                        stroke="none"
                        dataKey={dataKey}
                        name={symbol}
                        key={`area-gradient-${symbol}`}
                        legendType="circle"
                        animationDuration={300}
                        activeDot={{ r: 5, stroke: color }}
                    />
                    <Line
                        type="monotone"
                        stroke={color}
                        dataKey={(item: ProcessedIndexItem) => {
                            return item.symbolValues[symbol];
                        }}
                        dot={false}
                        legendType="none"
                        animationDuration={300}
                        activeDot={false}
                        name="none"
                        strokeWidth={symbol === 'PINKCHIP' ? 1.5 : 1}
                    />
                </>
            );
        };

        const getLines = ([...symbols]: Array<string>) => {
            const indexSymbolPosition = symbols.indexOf(indexSymbol);
            if (enableGradient && indexSymbolPosition !== -1 && !showRawValues) {
                symbols.splice(indexSymbolPosition, 1);
            }
            const lines = symbols.map((symbol) => {
                if (disabledSymbols.includes(symbol)) return null;

                const dataKey = (item: ProcessedIndexItem) => {
                    if (symbol === indexSymbol && enableGradient && !obfuscateBackfill && item.isBackcalculated) {
                        return null;
                    }
                    return item.symbolValues[symbol];
                };
                return (
                    <Area
                        type="monotone"
                        fill="none"
                        dot={false}
                        activeDot={!highlightPrimaryOnly}
                        dataKey={dataKey}
                        name={symbol}
                        key={`line-${symbol}`}
                        stroke={colorMap.get(symbol)}
                        legendType="circle"
                        animationDuration={300}
                        strokeWidth={(symbolList.length > 1 && indexSymbol === symbol) || !symbol ? 2 : 1.5}
                        connectNulls={true}
                    />
                );
            });

            if (hasBackcalculatedData) {
                const symbol = indexSymbol;
                const dataKey = (item: ProcessedIndexItem) => {
                    // Draw to the first non-calculated to ensure the lines touch
                    if (!item.isBackcalculated && item.asOfDate !== timeRangeFirstLiveDate) {
                        return null;
                    }
                    return item.symbolValues[symbol];
                };

                lines.unshift(
                    <Area
                        type="monotone"
                        fill="none"
                        dot={false}
                        dataKey={dataKey}
                        name={symbol}
                        key={`line-${symbol}-backcalculated`}
                        stroke={colorMap.get(symbol)}
                        legendType="circle"
                        animationDuration={300}
                        strokeWidth={symbolList.length > 1 ? 3 : 1.5}
                        connectNulls={true}
                        strokeDasharray={'3 3'}
                    />
                );
            }

            return lines;
        };

        // TODO: specify axis tick color
        const axisTick = { fill: '#000000', fontSize: 12 };
        const yTicks = generateYTicks(minValue, maxValue);

        const zoomProps = zoomEnabled
            ? {
                  onMouseDown: (nextState: { activeLabel?: string }, e: MouseEvent): void => {
                      if (e.preventDefault) e.preventDefault();
                      setZoomStartEdgeDate(nextState?.activeLabel);
                      setZoomEndEdgeDate(undefined);
                  },
                  onMouseMove: (nextState: { activeLabel?: string }) => {
                      if (zoomStartEdgeDate) setZoomEndEdgeDate(nextState.activeLabel);
                  },
                  onMouseUp: () => {
                      if (zoomStartEdgeDate && zoomEndEdgeDate && zoomStartEdgeDate !== zoomEndEdgeDate) {
                          const edgeDates: [Date, Date] = [
                              ParseGqlUtcDate(zoomStartEdgeDate),
                              ParseGqlUtcDate(zoomEndEdgeDate),
                          ];
                          edgeDates.sort((d1, d2) => (d1 > d2 ? 1 : -1));
                          setCustomRange(edgeDates);
                          setSelectedTimeRange('custom');
                      }

                      setZoomStartEdgeDate(undefined);
                      setZoomEndEdgeDate(undefined);
                  },
              }
            : {};

        return (
            <ComposedChart
                data={graphData}
                style={{
                    cursor,
                    fontFamily: 'Satoshi-Regular',
                }}
                ref={forwardedRef || ref}
                {...zoomProps}
            >
                {showXLabels && showYLabels && (
                    <>
                        <ReferenceLine
                            x={graphData[graphData.length - 1]?.asOfDate}
                            stroke={border}
                        />
                        <ReferenceLine
                            x={graphData[0]?.asOfDate}
                            stroke={border}
                        />
                        <CartesianGrid vertical={false} />
                    </>
                )}
                {showRecessions && Recessions(graphData)}
                <XAxis
                    name="Date"
                    type="category"
                    dataKey="asOfDate"
                    tickFormatter={FormatDateMonthDayYear}
                    stroke={border}
                    allowDuplicatedCategory={false}
                    interval="preserveEnd"
                    axisLine={false}
                    tickSize={0}
                    tick={axisTick}
                    tickMargin={20}
                    minTickGap={30}
                    height={32}
                    hide={!showXLabels}
                />
                <YAxis
                    type="number"
                    stroke={border}
                    tickFormatter={tickValueFormatter}
                    tickMargin={10}
                    interval={0}
                    tick={axisTick}
                    tickSize={0}
                    hide={!showYLabels}
                    domain={showRawValues ? ['auto', 'auto'] : ['dataMin', 'dataMax']}
                    tickCount={5}
                    ticks={showYLabels ? yTicks : undefined}
                    width={showRawValues ? 90 : 50}
                />

                {hasBackcalculatedData && (
                    <ReferenceLine
                        x={timeRangeFirstLiveDate}
                        stroke="red"
                    />
                )}

                {showTooltip && (
                    <Tooltip
                        content={customToolTip}
                        wrapperStyle={{ outline: 'none' }}
                    />
                )}

                {(showLegend || exportingImage) && (
                    <Legend
                        verticalAlign="bottom"
                        align="right"
                        iconSize={10}
                        wrapperStyle={{
                            cursor: 'pointer',
                            paddingTop: '10px',
                        }}
                        payload={GetLegendData(symbolList, indexSymbol, colorMap, {
                            primaryOnly: highlightPrimaryOnly,
                        })}
                        onClick={(item) => {
                            if (disabledSymbols.includes(item.value)) {
                                setDisabledSymbols(disabledSymbols.filter((symbol) => symbol !== item.value));
                            } else {
                                setDisabledSymbols([...disabledSymbols, item.value]);
                            }
                        }}
                    />
                )}
                {enableGradient && getArea(indexSymbol, showYLabels ? yTicks[0] : minValue)}
                {getLines(symbolList)}
                <ReferenceLine
                    y={0}
                    stroke={zeroLine}
                />
                {zoomStartEdgeDate && zoomEndEdgeDate ? (
                    <ReferenceArea
                        x1={zoomStartEdgeDate}
                        x2={zoomEndEdgeDate}
                        fill="gray"
                        fillOpacity={0.5}
                    />
                ) : null}
            </ComposedChart>
        );
    }, [
        minValue,
        maxValue,
        zoomEnabled,
        graphData,
        cursor,
        forwardedRef,
        ref,
        showXLabels,
        showYLabels,
        showRecessions,
        tickValueFormatter,
        showRawValues,
        hasBackcalculatedData,
        timeRangeFirstLiveDate,
        showTooltip,
        customToolTip,
        showLegend,
        exportingImage,
        symbolList,
        indexSymbol,
        colorMap,
        highlightPrimaryOnly,
        enableGradient,
        zeroLine,
        zoomStartEdgeDate,
        zoomEndEdgeDate,
        disabledSymbols,
        obfuscateBackfill,
    ]);

    return (
        <div>
            <div className={cn('relative', 'bg-white', 'pv-2', 'lg:ph-2', className)}>
                {title && (
                    <Heading
                        importance={2}
                        className="text-brand-black mb-2"
                    >
                        {title}
                    </Heading>
                )}
                {showPercentChange && (
                    <PercentChange
                        symbolList={symbolList}
                        indexSymbol={indexSymbol}
                        data={graphData}
                        colorMap={colorMap}
                    />
                )}
                {(showTimeSelectors || showIndicators) && (
                    <div className="flex w-full justify-stretch items-end mb-4 flex-wrap gap-y-2">
                        {showIndicators && timeRangeRawData.length > 0 && (
                            <HeaderIndicators
                                colorMap={colorMap}
                                indexSymbol={indexSymbol}
                                timeRangeRawData={timeRangeRawData}
                            />
                        )}
                        {showTimeSelectors && TimeSelectors}
                    </div>
                )}
                <div
                    className={cn({
                        'flex flex-col-reverse lg:flex-row': showPercentChange,
                    })}
                >
                    <div className="relative flex-grow basis-full">
                        <ResponsiveContainer
                            width="100%"
                            height={height}
                        >
                            {chart}
                        </ResponsiveContainer>
                        <div className="flex justify-end">
                            {showDownloadButton && (
                                <Button
                                    type="action"
                                    onClick={() => {
                                        setExportingImage(true);
                                        setTimeout(() => {
                                            handleGraphDownload();
                                        }, 250);
                                    }}
                                    color="transparent"
                                    className="p-0 mt-4 text-brand-blue-yves ml-4 font-brand-bold"
                                >
                                    Export image
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                {showBackfillInfo && (
                    <span className="text-brand-gray-dark text-xs">
                        {indexSymbol} was introduced on {FormatUtcDate(firstLiveDate, 'MMMM d, yyyy')} and incorporates
                        historical data back to {FormatUtcDate(firstDateOfData, 'MMMM d, yyyy')} with an initial level
                        of {FormatCurrencyAmount(firstDateRawValue, { prefix: '' })}.
                    </span>
                )}

                {(showIndicators || showLogo) && (
                    <div className="mt-2 flex w-full justify-stretch items-end">
                        {showIndicators && (
                            <FooterIndicators
                                indexSymbol={indexSymbol}
                                fiftyTwoWeekData={FilterIndexDataDateRange(
                                    sortedRawData,
                                    TIME_RANGES['1y'][0],
                                    TIME_RANGES['1y'][1]
                                )}
                            />
                        )}
                        {showLogo && (
                            <a
                                href={`${SITE_BASE_URL}${GetIndexSymbolPublicPath(indexSymbol)}`}
                                target="_blank"
                                rel="noreferrer"
                                className="flex justify-end items-center no-underline flex-grow"
                            >
                                <Cube
                                    color="gray"
                                    size="xs"
                                />
                                <span className="text-brand-gray-dark text-xs ml-1">Built on Thematic</span>
                            </a>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
});

export default Graph;
